.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f3f3f3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-control {
  width: 190px !important;
  padding: 4px 10px 4px 60px !important;
  border: none !important;
  border-radius: 20px !important;
  color: black;
  font-weight: bold;
  font-size: 15px;
  font-family: "Baloo Thambi 2";
  opacity: 1;
  height: 28px !important;
}
@media only screen and (max-width: 410px) {
  .form-control {
    width: 130px !important;
  }
}

.form-control::placeholder {
  color: black;
  font-weight: bold;
  font-size: 15px;
  font-family: "Baloo Thambi 2";
}

.flag-dropdown {
  border: none !important;
}

.react-tel-input .selected-flag:before {
  transition: none !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  box-shadow: none !important;
  border: none !important;
}

.country-list {
  width: 200px !important;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.country {
  display: flex;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.country-name {
  display: inline-block;
  max-width: 88px;
  width: 88px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 6px !important;
}
